import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  ShopOutlined,
  PictureOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  CommentOutlined,
  FundProjectionScreenOutlined,
  BookOutlined,
  LikeOutlined,
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/user" />;
      } else {
        return <Redirect to="/login" />;
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <UserOutlined />,
      label: "회원 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "회원 목록",
        },
        component: loadable(() => import("../pages/user/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/user/Detail")),
      },
      {
        exact: true,
        path: "/withdrawal",
        sidebar: {
          label: "탈퇴회원 목록",
        },
        component: loadable(() => import("../pages/user/withdrawal/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/withdrawal/detail/:id",
        component: loadable(() => import("../pages/user/withdrawal/Detail")),
      },
      {
        exact: true,
        path: "/push",
        sidebar: {
          label: "푸시알림 발송",
        },
        component: loadable(() => import("../pages/user/push/Post")),
      },
      {
        exact: true,
        path: "/push/list",
        sidebar: {
          label: "알림 발송 목록",
        },
        component: loadable(() => import("../pages/user/push/List")),
      },
      {
        exact: true,
        path: "/push/reservation",
        sidebar: {
          label: "예약 발송 목록",
        },
        component: loadable(() => import("../pages/user/push/Reservation")),
      },
      {
        exact: true,
        path: "/template/list",
        sidebar: {
          label: "알림 템플릿 관리",
        },
        component: loadable(() => import("../pages/user/template/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/template/register",
        component: loadable(() => import("../pages/user/template/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/template/detail/:id",
        component: loadable(() => import("../pages/user/template/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/product",
    sidebar: {
      icon: <ShopOutlined />,
      label: "제품",
    },
    children: [
      {
        exact: true,
        path: "/index/:page",
        sidebar: {
          label: "제품 목록",
        },
        component: loadable(() => import("../pages/product/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/product/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/modify/:id",
        component: loadable(() => import("../pages/product/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/product/Register")),
      },
      {
        exact: true,
        path: "/keyword",
        sidebar: {
          label: "제품군별 키워드 설정",
        },
        component: loadable(() => import("../pages/product/keyword/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/keyword/register",
        component: loadable(() => import("../pages/product/keyword/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/keyword/modify/:id",
        component: loadable(() => import("../pages/product/keyword/Modify")),
      },
      {
        exact: true,
        path: "/category/detail/:page",
        sidebar: {
          label: "제품 상세분류 관리",
        },
        component: loadable(() => import("../pages/product/detail/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/detail/register/:id",
        component: loadable(() => import("../pages/product/detail/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/detail/modify/:id",
        component: loadable(() => import("../pages/product/detail/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/contents",
    sidebar: {
      icon: <PictureOutlined />,
      label: "컨텐츠",
    },
    children: [
      {
        exact: true,
        path: "/list/:page",
        sidebar: {
          label: "컨텐츠 목록",
        },
        component: loadable(() => import("../pages/contents/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/contents/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/contents/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/modify/:id",
        component: loadable(() => import("../pages/contents/Modify")),
      },
      {
        exact: true,
        path: "/category",
        sidebar: {
          label: "컨텐츠 카테고리 관리",
        },
        component: loadable(() => import("../pages/contents/category/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/register",
        component: loadable(() =>
          import("../pages/contents/category/Register")
        ),
      },
      {
        exact: true,
        visible: false,
        path: "/category/modify/:id",
        component: loadable(() => import("../pages/contents/category/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/review",
    sidebar: {
      icon: <FundProjectionScreenOutlined />,
      label: "리뷰",
    },
    children: [
      {
        exact: true,
        path: "/:page",
        sidebar: {
          label: "리뷰 목록",
        },
        component: loadable(() => import("../pages/review/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/review/Detail")),
      },
    ],
  },

  {
    exact: true,
    path: "/experience",
    sidebar: {
      icon: <LikeOutlined />,
      label: "체험단",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "체험단 목록",
        },
        component: loadable(() => import("../pages/experience/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/experience/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/modify/:id",
        component: loadable(() => import("../pages/experience/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/user/:id",
        component: loadable(() => import("../pages/experience/user/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/review/:id",
        component: loadable(() => import("../pages/experience/review/List")),
      },
    ],
  },
  {
    exact: true,
    path: "/site",
    sidebar: {
      icon: <SettingOutlined />,
      label: "사이트 설정",
    },
    children: [
      {
        exact: true,
        path: "/interest",
        sidebar: {
          label: "관심사 설정",
        },
        component: loadable(() => import("../pages/site/interest/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/interest/register",
        component: loadable(() => import("../pages/site/interest/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/interest/modify/:id",
        component: loadable(() => import("../pages/site/interest/Modify")),
      },
      {
        exact: true,
        path: "/banner",
        sidebar: {
          label: "배너 설정",
        },
        sub: [
          {
            exact: true,
            path: "/top/home",
            sidebar: {
              label: "홈 (상단띠배너)",
            },
            component: loadable(() => import("../pages/site/banner/top/Home")),
          },
          {
            exact: true,
            path: "/home/main",
            sidebar: {
              label: "홈 (메인 배너)",
            },
            component: loadable(() =>
              import("../pages/site/banner/home/main/List")
            ),
          },
          {
            exact: true,
            visible: false,
            path: "/home/main/modify/:id",
            component: loadable(() =>
              import("../pages/site/banner/home/main/Modify")
            ),
          },
          {
            exact: true,
            visible: false,
            path: "/home/main/register",
            component: loadable(() =>
              import("../pages/site/banner/home/main/Register")
            ),
          },
          {
            exact: true,
            path: "/contents/main",
            sidebar: {
              label: "컨텐츠 (메인배너)",
            },
            component: loadable(() =>
              import("../pages/site/banner/contents/main/List")
            ),
          },
          {
            exact: true,
            visible: false,
            path: "/contents/main/modify/:id",
            component: loadable(() =>
              import("../pages/site/banner/contents/main/Modify")
            ),
          },
          {
            exact: true,
            visible: false,
            path: "/contents/main/register",
            component: loadable(() =>
              import("../pages/site/banner/contents/main/Register")
            ),
          },
          {
            exact: true,
            path: "/review/main",
            sidebar: {
              label: "리뷰 (메인배너)",
            },
            component: loadable(() =>
              import("../pages/site/banner/review/main/List")
            ),
          },
          {
            exact: true,
            visible: false,
            path: "/review/main/modify/:id",
            component: loadable(() =>
              import("../pages/site/banner/review/main/Modify")
            ),
          },
          {
            exact: true,
            visible: false,
            path: "/review/main/register",
            component: loadable(() =>
              import("../pages/site/banner/review/main/Register")
            ),
          },
          {
            exact: true,
            path: "/event",
            sidebar: {
              label: "이벤트 (친구초대 메인배너)",
            },
            component: loadable(() =>
              import("../pages/site/banner/event/List")
            ),
          },
          {
            exact: true,
            visible: false,
            path: "/event/register",
            component: loadable(() =>
              import("../pages/site/banner/event/Register")
            ),
          },
          {
            exact: true,
            visible: false,
            path: "/event/modify/:id",
            component: loadable(() =>
              import("../pages/site/banner/event/Modify")
            ),
          },
          {
            exact: true,
            path: "/product",
            sidebar: {
              label: "제품군별 배너",
            },
            component: loadable(() =>
              import("../pages/site/banner/product/List")
            ),
          },
          {
            exact: true,
            visible: false,
            path: "/product/register/:mainCategory",
            component: loadable(() =>
              import("../pages/site/banner/product/Register")
            ),
          },
          {
            exact: true,
            visible: false,
            path: "/product/register/:mainCategory/:subCategory",
            component: loadable(() =>
              import("../pages/site/banner/product/Register")
            ),
          },
          {
            exact: true,
            visible: false,
            path: "/product/modify/:id",
            component: loadable(() =>
              import("../pages/site/banner/product/Modify")
            ),
          },
          {
            exact: true,
            path: "/top/event",
            sidebar: {
              label: "이벤트 (포인트 띠배너)",
            },
            component: loadable(() => import("../pages/site/banner/top/Event")),
          },
        ],
      },
      {
        exact: true,
        path: "/terms",
        sidebar: {
          label: "약관 및 가이드 설정",
        },
        component: loadable(() => import("../pages/site/terms/List")),
      },
      {
        exact: true,
        path: "/footer",
        sidebar: {
          label: "푸터 설정",
        },
        component: loadable(() => import("../pages/site/footer/List")),
      },
      {
        exact: true,
        path: "/search",
        sidebar: {
          label: "추천 검색어 관리",
        },
        component: loadable(() => import("../pages/site/search/List")),
      },
      {
        exact: true,
        path: "/onboarding",
        sidebar: {
          label: "온보딩 페이지 관리",
        },
        component: loadable(() => import("../pages/site/onboarding/List")),
      },
      {
        exact: true,
        path: "/category/main/main",
        sidebar: {
          label: "대분류 관리",
        },
        component: loadable(() => import("../pages/site/category/main/Main")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/main/register",
        component: loadable(() => import("../pages/site/category/main/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/sub/sub/:id",
        component: loadable(() => import("../pages/site/category/sub/Sub")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/sub/register/:id",
        component: loadable(() => import("../pages/site/category/sub/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/detail/detail/:id",
        component: loadable(() => import("../pages/site/category/detail/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/detail/register/:id",
        component: loadable(() => import("../pages/site/category/detail/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/info",
    sidebar: {
      icon: <CommentOutlined />,
      label: "고객센터",
    },
    children: [
      {
        exact: true,
        path: "/qna/:page",
        sidebar: {
          label: "1:1 문의 목록",
        },
        component: loadable(() => import("../pages/info/qna/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/qna/detail/:id",
        component: loadable(() => import("../pages/info/qna/Detail")),
      },
      {
        exact: true,
        path: "/product/user/:page",
        sidebar: {
          label: "제품정보 등록 요청 목록",
        },
        component: loadable(() => import("../pages/info/product/request/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/product/user/detail/:id",
        component: loadable(() =>
          import("../pages/info/product/request/Detail")
        ),
      },
      {
        exact: true,
        path: "/report",
        sidebar: {
          label: "신고 관리",
        },
        component: loadable(() => import("../pages/info/report/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/report/detail/review/:id",
        component: loadable(() => import("../pages/info/report/ReviewDetail")),
      },
      {
        exact: true,
        visible: false,
        path: "/report/detail/comment/:id",
        component: loadable(() => import("../pages/info/report/CommentDetail")),
      },
      {
        exact: true,
        path: "/faq",
        sidebar: {
          label: "FAQ",
        },
        component: loadable(() => import("../pages/info/faq/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/register",
        component: loadable(() => import("../pages/info/faq/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/modify/:id",
        component: loadable(() => import("../pages/info/faq/Modify")),
      },
      {
        exact: true,
        path: "/notice",
        sidebar: {
          label: "공지사항 관리",
        },
        component: loadable(() => import("../pages/info/notice/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/notice/register",
        component: loadable(() => import("../pages/info/notice/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/notice/modify/:id",
        component: loadable(() => import("../pages/info/notice/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/landing",
    sidebar: {
      icon: <BookOutlined />,
      label: "소개 페이지 관리",
    },
    children: [
      {
        exact: true,
        path: "/subscription",
        sidebar: {
          label: "구독 메일 관리",
        },
        component: loadable(() => import("../pages/landing/subscription/List")),
      },
      {
        exact: true,
        path: "/news",
        sidebar: {
          label: "지난 뉴스레터 관리",
        },
        component: loadable(() => import("../pages/landing/news/List")),
      },
      {
        exact: true,
        path: "/faq",
        sidebar: {
          label: "FAQ 관리",
        },
        component: loadable(() => import("../pages/landing/faq/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/register",
        component: loadable(() => import("../pages/landing/faq/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/modify/:id",
        component: loadable(() => import("../pages/landing/faq/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/admin",
    sidebar: {
      icon: <UsergroupAddOutlined />,
      label: "관리자",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "관리자 목록",
        },
        component: loadable(() => import("../pages/admin/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/admin/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/modify/:id",
        component: loadable(() => import("../pages/admin/Modify")),
      },
    ],
  },
];
